<template>
  <b-card
    title="账号管理"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="2">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            label-size="lg"
            label="账号"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchAccountName"
              placeholder="请输入要搜索的账号"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label-size="lg"
            label="角色名称"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchRoleName"
              placeholder="请输入角色名称"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label-size="lg"
            label="用户姓名"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchUserName"
              placeholder="请输入用户姓名"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label-size="lg"
            label="启用状态"
            label-for="input-lg"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="adminEnabledOptions"
              class="w-100"
              :reduce="val => val.value"
              v-model="searchEnabled"
              placeholder="请选择启用状态"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="tRight">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="pageAdmin(true)"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'auth-accountInfo'}"
        v-if="$checkButtonPermission('202001001')"
      >
        添加
      </b-button>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="enabledAdmins(true)"
        v-if="$checkButtonPermission('202001002')"
      >
        启用
      </b-button>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="enabledAdmins(false)"
        v-if="$checkButtonPermission('202001002')"
      >
        停用
      </b-button>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="openPasswordModal"
        v-if="$checkButtonPermission('202001003')"
      >
        修改密码
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      ref="adminTable"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageSize
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
      @on-cell-click="onCellClick"
      @on-select-all="onSelectAll"
      @on-row-dblclick="onRowDoubleClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'enabled'">
          <span>{{props.row.enabled ? '启用' : '禁用'}}</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- 编辑 -->
            <b-dropdown-item>
              <span>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="tCenter">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
               @change="currentPageHandleChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </template>
    </vue-good-table>

    <!-- 修改密码的弹窗 -->
    <template>
      <b-modal
        id="modal-password-closing"
        title="修改密码"
        @show="resetPasswordModal"
        @hidden="resetPasswordModal"
        @ok="handlePasswordUpdateOk"
      >
        <form ref="updatePasswordForm">
          <b-form-group
            label="密码"
            invalid-feedback="必须填写要更改的密码"
            :state="passwordState"
          >
            <b-form-input
              id="password-input"
              v-model="updatePassword"
              :state="passwordState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </template>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BCard,BButton,BModal,BDropdown,BDropdownItem,BFormTextarea,BFormRadio
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,VueGoodTable,BAvatar,BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BButton,
    vSelect,BModal,BDropdown,BDropdownItem,BFormTextarea,BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordState: null,
      currentPage: 1,
      pageSize: 10,
      searchAccountName: '',
      searchRoleName: '',
      searchUserName: '',
      searchEnabled: '',
      dir: false,
      adminEnabledOptions: [
        { label:'启用', value: true },
        { label: '禁用', value: false },
      ],
      roleOptions: [
      ],
      roleFilter:null,
      columns: [
        {
          label: '账号',
          field: 'accountName',
        },
        {
          label: '用户名称',
          field: 'userName',
        },
        {
          label: '电话',
          field: 'mobile',
        },
        {
          label: '创建人',
          field: 'createUName',
        },
        {
          label: '角色名称',
          field: 'roleName',
        },
        {
          label: '备注',
          field: 'remark',
        },        {
          label: '禁启用状态',
          field: 'enabled',
        },        {
          label: '创建时间',
          field: 'createTime',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      total: 0,
      searchTerm: '',
      updatePassword: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.pageAdmin()
  },
  methods: {
    checkUpdatePasswrodFormValidity() {
        const valid = this.$refs.updatePasswordForm.checkValidity()
        this.passwordState = valid
        return valid
    },
    handlePasswordUpdateOk(bvModalEvent){
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // 调用保存的方法
      this.handlePasswordSubmit()
    },
    resetPasswordModal(){
      this.updatePassword = ''
      this.passwordState = null
    },
    /**
     * 密码表单提交
     */
    handlePasswordSubmit(){
      if (!this.checkUpdatePasswrodFormValidity()) {
        return
      }
      let adminList = this.$refs['adminTable'].selectedRows
      if(adminList.length <= 0){
        this.$bvToast.toast("请选择要操作的账户", {
          title: `提醒`,
          variant: 'warning',
          solid: true
        })
        return
      }
      let adminIdList = []
      for(let i=0;i<adminList.length;i++){
        adminIdList.push(adminList[i].id)
      }
      // 请求地址
      request.post('tob/user/bAdmin/update/pwd', { userIdList:adminIdList, willUpdatePwd:this.$md5(this.updatePassword) }).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-password-closing')
            })
          }
        }
      })
    },
    openPasswordModal(){
      this.$nextTick(() => {
        this.$bvModal.show('modal-password-closing')
      })
    },
    enabledAdmins(enabled){
      let adminList = this.$refs['adminTable'].selectedRows
      if(adminList.length <= 0){
        this.$bvToast.toast("请选择要操作的账户", {
          title: `提醒`,
          variant: 'warning',
          solid: true
        })
        return
      }
      console.log('adminList',adminList)
      let adminIdList = []
      for(let i=0;i<adminList.length;i++){
        adminIdList.push(adminList[i].id)
      }
      request.post('tob/user/bAdmin/enabled', {userIdList: adminIdList,enabled: enabled}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
          this.pageAdmin()
        } else {
            this.$bvToast.toast(res.data.msg, {
              title: `提醒`,
              variant: 'danger',
              solid: true
            })
        }
      })
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageAdmin()
    },
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params){
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      if(params.column.field == 'action'){
        console.log(params.row)
        this.$router.push({name:'auth-accountInfo',params:params.row});
      }
    },
    pageAdmin(resetCurrentPage){
      if(resetCurrentPage)
        this.currentPage = 1
      request.get('tob/user/bAdmin/page', {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        accountName: this.searchAccountName,
        roleName: this.searchRoleName,
        userName: this.searchUserName,
        enabled: this.searchEnabled
      }).then(res => {
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('rows',this.rows)
          console.log('total',this.total)
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
